import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import '../templates/base.scss'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO'
import TrainingHero from '../components/Training/TrainingHero/TrainingHero'
import TrainingContent from '../components/Training/TrainingContent/TrainingContent'

const Training = ({ data }) => {
  const postNode = {
    title: `Training - ${config.siteTitle}`,
  }

  const solutions = data && data.allContentfulSolution.edges
  const filteredSolutions = solutions.filter(a => {
    console.log(a)
    if (a.node.slug.includes('training')) {
      return true
    } else {
      return false
    }
  })

  return (
    <Layout>
      <Helmet>
        <title>{`Training - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="training" customTitle />
      <TrainingHero />
      <TrainingContent solutions={filteredSolutions} />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulSolution(limit: 100) {
      edges {
        node {
          title
          slug
          tags {
            title
            slug
          }
          hero {
            title
            subTitle
            callToAction
            callToActionLink
            background {
              title
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          metaDescription {
            internal {
              content
            }
          }
          body {
            content {
              nodeType
              content {
                value
              }
            }
          }
        }
      }
    }
  }
`

export default Training
