import React from 'react'
import './TrainingForm.scss'
import { FaCheckCircle, FaFlag, FaCloud, FaPaperPlane } from 'react-icons/fa'
const axios = require('axios')

export class TrainingForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideForm: false,
    }
  }

  showThankYou() {
    this.setState({ hideForm: true })
  }

  onchangeText(val) {
    var OriginalValue = document.getElementById('name').value
    document.getElementById('first_name').value = OriginalValue.substr(
      0,
      OriginalValue.indexOf(' ')
    )
    document.getElementById('last_name').value = OriginalValue.substr(
      OriginalValue.indexOf(' ') + 1
    )
  }

  submitForm(ev) {}

  renderFormSuccess() {
    let el
    if (this.state.hideForm) {
      el = (
        <div className="thanks-wrap">
          <div className="thanks-text">
            <div>
              Thanks for the message! We'll be in touch within the next day or
              so.
            </div>
            <div className="thanks-icon">
              <FaCheckCircle />
            </div>
          </div>
        </div>
      )
    } else {
    }
    return el
  }

  renderForm() {
    let el
    el = (
      <div>
        <form
          onSubmit={this.submitForm.bind(this)}
          action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          method="POST"
          id="TRAINING FORM"
          noValidate
        >
          <input type="hidden" name="oid" value="00D4T000000FZaP" />
          <input type="hidden" name="retURL" value="http://rackner.com?thankyou=training" />
          <input
            type="hidden"
            id="first_name"
            maxLength="40"
            name="first_name"
            size="20"
          />
          <input
            type="hidden"
            id="last_name"
            maxLength="80"
            name="last_name"
            size="20"
          />
          <div className="_form-content">
            <div className="_form_element _x70061606 _full_width ">
              <div className="_field-wrapper">
                <input
                  type="text"
                  onChange={this.onchangeText}
                  name="name"
                  id="name"
                  placeholder="Full Name"
                />
              </div>
            </div>
            <div className="_form_element _x28370249 _full_width ">
              <div className="_field-wrapper">
                <input
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  required
                />
              </div>
            </div>
            <div className="_form_element _field1 _full_width ">
              <div className="_field-wrapper">
                <input type="text" name="company" placeholder="Company" />
              </div>
            </div>
            <div className="_form_element _field2 _full_width ">
              <div className="_field-wrapper select">
                <select name="00N4T000004EGRg" id="00N4T000004EGRg" required>
                  <option className="default" value="">
                    Training Type
                  </option>
                  <option value="Kubernetes 2-Hour Bootcamp">
                    Kubernetes 2-Hour Bootcamp
                  </option>
                  <option value="Kubernetes Technical Training">
                    Kubernetes Technical Training
                  </option>
                  <option value="Kubernetes Executive Training">
                    Kubernetes Executive Training
                  </option>
                  <option value="DevSecOps CI/CD Training">
                    DevSecOps CI/CD Training
                  </option>
                  <option value="Custom Training">Custom Training</option>
                </select>
              </div>
            </div>
            <div className="_form_element _field3 _full_width ">
              <div className="_field-wrapper select">
                <select name="00N4T000004EGRl" id="00N4T000004EGRl" required>
                  <option className="default" value="">
                    How many people will be present for training?
                  </option>
                  <option value="1-5">1-5</option>
                  <option value="6-10">6-10</option>
                  <option value="11-15">11-15</option>
                  <option value="15+">15+</option>
                </select>
              </div>
            </div>
            <div className="_form_element _field1 _full_width ">
              <div className="_field-wrapper">
                <textarea
                  type="text"
                  name="description"
                  placeholder="Description"
                />
              </div>
            </div>
            <div className="_button-wrapper _full_width">
              <button id="_form_1_submit" className="form-button" type="submit">
                Submit
              </button>
            </div>
            <div className="_clear-element" />
          </div>
          <div
            className="_form-thank-you"
            style={{
              display: 'none',
            }}
          />
        </form>
      </div>
    )
    return el
  }

  render() {
    return (
      <div id="signup" className="training-form-upper-row padding-large">
        <div className="training-form-row">
          <div className="training-form-wrap">
            {this.state.hideForm ? this.renderFormSuccess() : this.renderForm()}
          </div>
          <div className="training-form-text">
            <h2>Go cloud native, with Rackner. We'll grease the wheels</h2>
            <h3>Fill out the form and we'll be in touch ASAP.</h3>
            <div className="benefits-row">
              <div className="benefit-col">
                <div className="benefit-inner">
                  <div className="icon-wrap">
                    <FaFlag />
                  </div>
                  <div className="text-line">Native English Speakers</div>
                </div>
              </div>
              <div className="benefit-col">
                <div className="benefit-inner">
                  <div className="icon-wrap">
                    <FaCloud />
                  </div>
                  <div className="text-line">Cloud Certified</div>
                </div>
              </div>
              <div className="benefit-col">
                <div className="benefit-inner">
                  <div className="icon-wrap">
                    <FaPaperPlane />
                  </div>
                  <div className="text-line">Expert Consultants</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TrainingForm
