import React from 'react'
import './TrainingContent.scss'
import * as Fa from 'react-icons/fa'
import TrainingForm from '../../TrainingForm/TrainingForm'
import SolutionsFeed from '../../Solutions/SolutionsFeed/SolutionsFeed'

export class TrainingContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideForm: false,
    }
  }

  render() {
    return (
      <div className="training-content">
        <div className="split light no-padding no-overflow">
          <section className="padding-large">
            <div className="row">
              <div className="col-md-6 col-md-push-6">
                <h3>
                  Learn cloud native - completely adapted for your use case
                </h3>
                <div className="service-row">
                  <div className="text-wrap">
                    <div className="title">Walk the talk</div>
                    <p>
                      Our courses are presented by Certified cloud native
                      engineers with experience leading Kubernetes
                      implementations and defining best practices at scale for
                      large <b>Fortune 500</b> and private organizations,
                      including venture-backed startups and 9-figure public
                      sector projects.
                    </p>
                  </div>
                </div>
                <div className="service-row">
                  <div className="text-wrap">
                    <div className="title">In-depth expertise</div>
                    <p>
                      Our presenters are native english speakers and authors of
                      technical books on Kubernetes and cloud native. They have{" "}
                      <b>
                        day-to-day experience driving real-world implementations
                      </b>
                      , not just theory.
                    </p>
                  </div>
                </div>
                <div className="service-row">
                  <div className="text-wrap">
                    <div className="title">Adapted for your organization</div>
                    <p>
                      We've built and modernized cloud-native solutions for many
                      industries and use cases - let us personalize your
                      training to your exact business use cases and technology
                      needs
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-md-pull-6">
                <div className="alt-solar">
                  <div className="line" />
                  <div className="planet1 planet-alt">
                    <Fa.FaCube />
                  </div>
                  <div className="planet2 planet-alt">
                    <Fa.FaCode />
                  </div>
                  <div className="planet3 planet-alt">
                    <Fa.FaCodeBranch />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="solar-wrap">
            <div className="ring-saturn" />

            <div className="saturn">
              <div className="planet">
                <Fa.FaCube />
              </div>
            </div>

            <div className="ring-jupiter" />

            <div className="jupiter2">
              <div className="planet">
                <Fa.FaCode />
              </div>
            </div>

            <div className="ring-pluto" />

            <div className="pluto">
              <div className="planet">
                <Fa.FaCodeBranch />
              </div>
            </div>
          </div>
        </div>
        <div className="split light">
          <h1>Hybrid Curriculum</h1>
          <div className="row migration-paths">
            <div className="col-sm-6">
              <div className="path">
                <div className="title">50% Education</div>
                <div className="icon-wrap">
                  <img src="/educate_icon.svg" />
                </div>
                <div className="description">
                  Immersive group training for Cloud, DevSecOps, and Kubernetes
                  (k8s). Learn the technologies, ecosystem - and how they can be
                  applied to your organization in an efficient way
                </div>
                <ul>
                  <li>
                    Leave your team with a significant understanding of
                    underlying cloud technologies, and how they might be used to
                    enhance your core business processes and applications.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="path">
                <div className="title">50% Hands on Problem Solving</div>
                <div className="icon-wrap">
                  <img src="/consult_icon.svg" />
                </div>
                <div className="description">
                  Leverage one of our architects for a couple days and learn how
                  specific parts of your technology portfolio can be modernized,
                  or take advantage of technologies like Kubernetes
                </div>
                <ul>
                  <li>
                    We talk about constraints, migration pitfalls, Total Cost of
                    Ownership (and how it compares with on-premise solutions),
                    as well as specific solutions to your problems.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="split pattern">
          <div className="training-solutions-wrapper">
            <h1>Recommended for your organization</h1>
            <SolutionsFeed
              hideHeader={true}
              solutions={this.props?.solutions}
            />
          </div>
        </div>

        <TrainingForm />
      </div>
    )
  }
}

export default TrainingContent
