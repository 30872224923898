import React from 'react'
import './TrainingHero.scss'

export class TrainingHero extends React.Component {
  render() {
    return (
      <div
        className="contact-hero"
        style={{ backgroundImage: 'url(/contactbg.jpg)' }}
      >
        <div className="overlay" />
        <div className="inner-wrap">
          <div className="text">
            <h1>Cloud Native Training</h1>
            <h2>Deep-dives into Cloud, DevOps, Containers and Kubernetes.</h2>
          </div>
        </div>
      </div>
    )
  }
}

export default TrainingHero
